<template>
  <b-card class="shop-payment-config">
    <b-tabs
      pills
      class="user-profile-info justify-content-center mb-5"
    >
      <b-tab active>
        <template #title>
          <span class="d-inline">{{ $t('Incoming Orders') }}</span>
        </template>
        <div class="wrap-incoming-orders">
          <h2
            v-if="dispatcherList.length === 0"
            class="text-center mt-3"
          >
            {{ $t('No orders found') }}
          </h2>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <span class="d-inline">{{ $t('Accepted Orders') }}</span>
        </template>
        <div class="wrap-accepted-orders">
          <h2
            v-if="accpetedList.length === 0"
            class="text-center mt-3"
          >
            {{ $t('No orders found') }}
          </h2>
          <b-row v-if="accpetedList.length > 0">
            <b-col
              v-for="(item, index) of accpetedList"
              :key="index"
              md="4"
            >
              <b-card class="mb-1 shadow-card">
                <b-card-body class="py-0">
                  <p class="mb-0">
                    {{ $t('Name') }}: {{ item.user.first_name }} {{ item.user.last_name }}
                  </p>
                  <p class="mb-0">
                    {{ $t('Number') }}: {{ item.user.mobile }}
                  </p>
                  <p class="mb-0">
                    {{ $t('Payment') }}: {{ item.invoice.payment_mode }}
                  </p>
                  <div class="mt-1">
                    <button class="btn btn-primary">
                      {{ $t('Order List') }}
                    </button>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BTabs, BTab, BRow, BCol, BCardBody } from 'bootstrap-vue'
import { showErrorNotification } from '@/@core/comp-functions/ui/app'
import { useStoreUi } from './useStore'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCardBody,
  },
  data() {
    return {
      dispatcherList: [],
      accpetedList: [],
    }
  },
  mounted() {
    this.dispatcherInfo('ORDERED')
    this.dispatcherInfo('ACCEPTED')
  },
  methods: {
    dispatcherInfo(type) {
      const { dispatcherInfo } = useStoreUi()

      dispatcherInfo(type)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (type === 'ORDERED') this.dispatcherList = data.responseData
            else this.accpetedList = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style>
.shop-payment-config .user-profile-info ul.nav.nav-pills {
  justify-content: center;
}

.card .card.shadow-card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}
</style>
